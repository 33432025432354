@import "./../../Global.scss";

.breadcrumb {
    background: #ffffff;
    color: #ccc;
    border-top: 1px solid #e5e5e5;
    margin: 0 auto;

    .content {
        margin: 8px 15px;
        
        span {
            color: #999;
            margin-left: 5px;
        }
    }
}