$primary-color: #3194be;
$secondary-color: #14D2E8;
$primary-text: #424242;

.primary-color {
    color: $primary-color;
}

.primary-text {
    color: $primary-text;
}

.btn {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.primary-btn {
    color: #fff;
    background-color: $secondary-color;
}

.secondary-btn {
    color: fff;
    background-color: $primary-color;
}

.icon {
    width: 2rem;
    height: 2rem;
}