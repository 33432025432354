@import "../Global.scss";

.footer {
    background: #464646;
    color: #c4c5c6;
    
    .footerCol1 {
        color: #c0c0c0;
        padding: 40px 0;

        h5 {
            font-size: 1rem;
        }

        ul {
            font-size: 0.8rem;
            list-style: disc;

            li {
                a {
                    color: #c0c0c0;
                    text-decoration: none;
                }
            }
        }
    }
    
    .footerCol2 {
        position: relative;
        padding: 30px 0;
        border-top: 1px solid #535353;

        &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #393939;
        }

        .copy-right,
        ul {
            padding: 0;
            font-size: 0.8rem;
            list-style: none;
        }
    }

    .company-detail {
        position: relative;
        padding: 30px 0;

        .copy-right,
        ul {
            padding: 0;
            font-size: 0.8rem;
            list-style: none;
        }
    }
}