.App {
  /* text-align: center; */
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}

.cart-icon {
  position: relative;
  font-size: 24px;
  margin-bottom: 20px;
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: red;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 6px;
}

.add-to-cart-btn {
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.add-to-cart-btn:hover {
  background-color: #0056b3;
}

.cart-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.cart-item img {
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cart-item span:focus {
  outline: 2px solid #007bff; /* Adds a blue outline to the focused element */
  border-radius: 3px;
}

.payment-status {
  margin-top: 10px;
  font-size: 14px;
  color: #007bff; /* Blue for success */
}

.payment-status.error {
  color: #ff0000; /* Red for error */
}

.btn-proceed-payment {
  /* Basic Styles */
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #007bff; /* Blue border */
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-proceed-payment:disabled {
  background-color: #6c757d; /* Grey background when disabled */
  border-color: #6c757d; /* Grey border when disabled */
  cursor: not-allowed;
}

.btn-proceed-payment:hover:not(:disabled) {
  background-color: #0056b3; /* Darker blue on hover */
  border-color: #0056b3; /* Darker blue border on hover */
}

.btn-proceed-payment:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus ring */
}

.btn-back {
  margin-right: 5px;
  align-items: center;
  background-color: #f8f9fa; /* Light background */
  color: #6c757d; /* Gray text */
  border: 1px solid #6c757d; /* Border to match text color */
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.btn-back:hover {
  background-color: #e2e6ea; /* Slight hover effect */
}

.all-button {
  display: flex;
}

.sub-total {
  margin-left: auto;
  margin-right: 10px;
  padding: 10px;
  border-top: 2px dotted black;
  border-bottom: 2px dotted black;
}