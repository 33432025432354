@import "./../Global.scss";

.header {
    .header-menu {
        position: relative;
        background-color: #202020;
        background-image: -moz-linear-gradient(top,#2b2b2b,#111111);
        background-image: -webkit-gradient(linear,0 0,0 100%,from(#2b2b2b),to(#111111));
        background-image: -webkit-linear-gradient(top,#2b2b2b,#111111);
        background-image: -o-linear-gradient(top,#2b2b2b,#111111);
        background-image: linear-gradient(to bottom,#2b2b2b,#111111);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff2a2a2a', endColorstr='#ff111111', GradientType=0);
        border-top: 1px solid #373737;
        border-bottom: 1px solid #1e1e1e;
        opacity: 0.9;
        z-index: 999;
        
        .logo {
             img {
                width: 100px;
            }
        }

        .main-nav {
            ul {
                padding: 0;
                margin: 0 auto;

                li {
                    display: inline-block;
                    
                    a {
                        color: #fff;
                        display: inline-block;
                        padding: 28px 50px;
                        text-decoration: none;
                        
                        &.active {
                            background-color: #d85835;
                        }
                    }
                }
            }
        }
    }
}

