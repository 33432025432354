@import "./../Global.scss";

.home {
    .home-banner {
        background-color: #FFFFFF;
        background-image: url("./../assets/images/banner_bg.jpg");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
        margin-top: -5.2rem;
        padding: 10rem 0;

        .content {
            h1 {
                display: inline-block;
                background-color: #fffc;
                color: #ff7302;
                font-size: 2.5rem;
                padding: 6px 12px;
            }
            
            h3 {
                display: inline-block;
                background-color: #000;
                color: #fff;
                font-size: 2.3rem;
                font-weight: bold;
                padding: 6px 12px;
            }
        }
    }

    .home-content-1 {
        background: #464646;
        background: #464646;
        background: -moz-linear-gradient(top,#f8eaea 0%, #ddd7d7 50%, #ffffff 100%);
        background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#464646),color-stop(50%,#606060),color-stop(100%,#464646));
        background: -webkit-linear-gradient(top,#f8eaea 0%, #ddd7d7 50%, #ffffff 100%);
        background: -o-linear-gradient(top,#f8eaea 0%, #ddd7d7 50%, #ffffff 100%);
        background: -ms-linear-gradient(top,#f8eaea 0%, #ddd7d7 50%, #ffffff 100%);
        background: linear-gradient(to bottom,#f8eaea 0%, #ddd7d7 50%, #ffffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@secondry_color',endColorstr='@secondry_color',GradientType=0);
        color: #e6e6e6;
        border-top: 1px solid #606060;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -ms-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
        padding: 3rem 0;

        .box {
            margin-bottom: 2rem;

            .icons {
                background: #e6e6e6;
                color: #464646;
                border: 1px solid #ffffff;
                -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.8);
                -moz-box-shadow: 0 1px 5px rgba(0,0,0,0.8);
                box-shadow: 0 1px 5px rgba(0,0,0,0.8);
                -webkit-box-shadow: 0 1px 15px rgba(0,0,0,0.3) inset;
                -moz-box-shadow: 0 1px 15px rgba(0,0,0,0.3) inset;
                box-shadow: 0 1px 15px rgba(0,0,0,0.3) inset;
                -webkit-transition: all 0.5s linear;
                -moz-transition: all 0.5s linear;
                -ms-transition: all 0.5s linear;
                -o-transition: all 0.5s linear;
                transition: all 0.5s linear;
                font-size: 2rem;
                text-align: center;
                padding: 18px;
                line-height: 40px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                width: 78px;
                height: 78px;
                display: inline-block;
            }

            .content {
                display: inline-block;
                width: calc(100% - 90px);
                float: right;
            }
        }
    }

    .home-content-2 {
        background: #f2f2f2;
        padding: 60px 0;
        
        .tab-content {
            height: 100%;
            min-height: 300px;

            .product-slider {
                // width: 80%;
                overflow-x: scroll;
                overflow-y: hidden;
                padding: 0 5px;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                background-color: #fff;
            }

            .item-block {
                display: inline-block;
                color: #e5171d;
                border-radius: 12px;
                width: 215px !important;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                -js-display: flex;
                display: flex;
                -webkit-box-flex: 1;
                -webkit-flex-grow: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                position: relative;
                margin: 3px 5rem 3px 3px;

                .image-wrap {
                    display: flex;
                    -webkit-box-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    position: relative;
                    background: #fff;
                    margin-bottom: -1px;
                    border-radius: 12px 12px 0 0;
                    border-radius: 12px 12px 12px 12px;
                    border: 1px solid #c4c3c3;
                    overflow: hidden;
                    width: 215px !important;
                    height: 215px !important;
                    padding: 6px;
                }

                .product-details {
                    color: #000;
                    min-height: 80px;

                    .product-details-title {
                        margin-top: 6px;
                        color: #363433;
                        -webkit-box-flex: 1;
                        -webkit-flex-grow: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        line-height: 16px;
                        font-size: 14px;

                        .product-name {
                            font-weight: 700;
                            margin: 6px 0;
                            min-height: 32px;
                        }
                    }

                    .product-price {
                        font-weight: 700;
                        font-size: 14px;
                        color: #000;
                    }
                }
            }
        }
        
        h5 {
            background: url(./../assets/images/mod_border.png) repeat-x 0 15px;
            margin-bottom: 2rem;    
            
            span {
                background: #f2f2f2;
                color: #666666;
                padding: 0px 10px 0 0;
                font-size: 1rem;
            }
        }
        
        .nav-tabs {
            border: 0;
            
            li {
                width: calc(100% - 75%);
            }

            .nav-link {
                background: #ffffff;
                border: 1px solid #e5e5e5;
                border-bottom: 4px solid #d9d9d9;
                border-right: none;
                border-radius: 0;
                -webkit-transition: all 0.5s linear;
                -moz-transition: all 0.5s linear;
                -ms-transition: all 0.5s linear;
                -o-transition: all 0.5s linear;
                transition: all 0.5s linear;
                color: #666666;
                width: 100%;
                
                &.active {
                    color: #666666;
                    border-bottom: 4px solid #d85835;
                }
            }
        }
        
        .imgRow {
            float: left;
        }
        
        .contentRow {
            h2 {
                text-transform: uppercase;
                color: #999999;
                font-size: 2rem;
            }
            
            h3 {
                color: #666666;
                margin-bottom: 1.5rem;    
                font-weight: 400;
            }

            p {
                a {
                    color: #ff6600;
                    text-decoration: none;
                }
                
                a {
                    &.lastTag {
                        color: #8c331b;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .home-banner {
            .content {
                .col {
                    &:nth-last-child(1) {
                        display: none;
                    }
                }
            }
        }
    }
   
    @media (max-width: 991px) {
        .home-content-2 {
            .imgRow {
                float: inherit;

                img {
                    margin: 0 auto;
                }
            }

            .nav-tabs {
                li {
                    width: 50%;
                }
            }
        }
    }

    @media (max-width: 425px) {
        .home-banner {
            padding: 12rem 0 8rem;

            .content {
                h1 {
                    font-size: 1.5rem;
                }

                h3 {
                    font-size: 1.3rem;
                }
            }
        }

        .home-content-1 {
            padding: 2rem 0 0;
        }

        .home-content-2 {
            .nav-tabs {
                li {
                    width: 100%;
                }
            }
        }
    }
}

.cutwords-two-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    position: relative; /* Ensures the hover effect works properly */
    transition: all 0.3s ease; /* Smooth transition */
}

.container-header {
    display: flex;
    justify-content: space-between;
}

.cutwords-two-line:hover {
    -webkit-line-clamp: unset; /* Show all lines on hover */
    white-space: normal; /* Allow text wrapping */
    overflow: visible; /* Show the entire content */
    background-color: #fff; /* Optional: Highlight background for better visibility */
    z-index: 1; /* Ensure the hovered text is above other elements */
    padding: 5px; /* Optional: Add padding for better hover experience */
    border-radius: 5px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
  }